import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import React from 'react'

import { cn } from '~/utils/cn'

const spinnerStyle = cva(
  `
  relative

  animate-spin

  before:absolute
  before:left-0
  before:top-0
  before:block
  before:h-full
  before:w-full
  before:rounded-full
  before:border-current
  before:opacity-24
  
  after:left-0
  after:top-0
  after:block
  after:h-full
  after:w-full
  after:rounded-full
  after:border-transparent
  after:border-r-current
  after:border-t-current
  `,
  {
    variants: {
      size: {
        '2xs': `
          h-2
          w-2
          before:border
          after:border
        `,
        xs: `
          h-3
          w-3
          before:border
          after:border
        `,
        sm: `
          h-3.5
          w-3.5
          before:border-[1.5px]
          after:border-[1.5px]
        `,
        md: `
          h-5
          w-5
          before:border-2
          after:border-2
        `,
        lg: `
          h-6
          w-6
          before:border-2
          after:border-2
        `,
      },
    },
  }
)

export interface SpinnerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof spinnerStyle> {}

export const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  ({ className, size = 'md', ...props }, ref) => (
    <div
      aria-label="loading"
      role="progressbar"
      className={cn(spinnerStyle({ size, className }))}
      ref={ref}
      {...props}
    />
  )
)
Spinner.displayName = 'Spinner'
