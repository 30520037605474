// custom Slottable to allow multiple elements to be passed as children of <Slot />
// see https://github.com/radix-ui/primitives/issues/1825

import React from 'react'

type SlottableProps = {
  asChild: boolean
  child: React.ReactNode
  children: (child: React.ReactNode) => JSX.Element
}

export const Slottable = ({
  asChild,
  child,
  children,
  ...props
}: SlottableProps) => {
  return (
    <>
      {asChild
        ? React.isValidElement(child)
          ? React.cloneElement(child, props, children(child.props.children))
          : null
        : children(child)}
    </>
  )
}
